// import { calculateVariant } from './variantCalculator'
// const moment = require('moment');

export function generateViewData(rawData) {
    var viewData = [];
    rawData.forEach(device => {
        var viewDevice = {
            name: device.name,
            description: device.description,
            id: device.id,
            httpPath: device.httpPath,
            online: device.online,
            timestamp: device.timestamp,
        };

        // let onlinestate = viewDevice.dataRows[0].data[0].variant;
        // let timestamtstate = viewDevice.dataRows[0].data[1].variant;

        // viewDevice.onlinestate = onlinestate;
        // viewDevice.state = getWorst(getWorst(device.variant, onlinestate), timestamtstate);
        viewData.push(viewDevice);
    });
    return viewData;
}
