const dateTimeFormats = {
  en: {
    'date-short': { year: "numeric", month: "numeric", day: "numeric" },
    'datetime-short': { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" },
  },
  de: {
    'date-short': { year: "numeric", month: "2-digit", day: "2-digit" },
    'datetime-short': { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"},
  }
}

export default dateTimeFormats