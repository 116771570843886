const state = {
    alert: {
        message: '',
        type: '',
        title: '',
    }
};

const getters = {
    getMessage: state => state.alert.message,
    getType: state => state.alert.type,
    getTitle: state => state.alert.title,
};

const actions = {
    /**
     * Displays a toast message at the top of the screen.
     * @param {*} param0 Title
     * @param {*} param1 Message
     */
    fireAlert({ commit }, [title, message, type]) {
        console.log("**Alert:**", title, message, '|', `(${type})`);
        commit('setAlert', [title, message, type]);
        setTimeout(()=> {
            commit('resetAlert');
        }, 500);
    },
    resetAlert({ commit }) {
        commit('resetAlert');
    },
}

const mutations = {
    setAlert: (state, [title, message, type]) => {
        state.alert = { message, type, title };
    },
    resetAlert: (state) => {
        state.alert = { message: '', type: '', title: '' };
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}