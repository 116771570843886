import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'


Vue.use(VueRouter)

const routes = [
  {
    path: '/', //Home
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import(/* webpackChunkName: "devices" */ '../views/DeviceSelect.vue')
  },
  {
    path: '/devices/device',
    name: 'Device',
    component: () => import(/* webpackChunkName: "device" */ '../views/Device.vue')
  },
  {
    path: '/devices/:deviceid/properties',
    name: 'DeviceProperties',
    component: () => import(/* webpackChunkName: "deviceproperties" */ '../views/DeviceProperties.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/users/create-user',
    name: 'CreateUser',
    component: () => import(/* webpackChunkName: "create-user" */ '../views/CreateUser.vue')
  },
  {
    path: '/users/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/users/:username',
    name: 'User',
    props: true,
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import(/* webpackChunkName: "roles" */ '../views/Roles.vue')
  },
  {
    path: '/roles/:name',
    name: 'Role',
    props: true,
    component: () => import(/* webpackChunkName: "role" */ '../views/Role.vue')
  },
  // {
  //   path: '*',
  //   redirect() {
  //     return Trans.defaultLocale;
  //   }
  // }

]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (/*to, from, savedPosition*/) {
    return { x: 0, y: 0 , behavior: 'smooth'}
  }
  
})




router.beforeEach((to, from, next) => {
  // const { locale } = to.params;

  // //change locale via URL
  // if (to.params.locale != from.params.locale) {
  //   i18n.locale = locale;
  //   setDocumentLang(locale)
  // }

 
  const publicPages = [`/login`, `/register`, `/`, '/reset-password'];
  
  publicPages.forEach((page) => {
    publicPages.push(page+'/');
  })
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters['users/getLoggedin'];

  if (authRequired && !loggedIn) {
    //if not loggedin continue to login-page no matter what user requested
    return next(`/login`);
  }


  store.dispatch('alerts/resetAlert', null, { root: true });
  next(); //page is eighter public or user is logged in -> grant access.
})

export default router
