import axios from 'axios'
// const qs = require('querystring')
import { generateViewData } from '@/assets/viewdataGenerator.js'
// import { search } from 'core-js/fn/symbol';
// import { host, auth } from '@/globals.js'

const state = {
    rawDevices: [],
    viewDevices: [],

    searchString: '',
    sortorder: 'danger',

    hidelist: [],
    groups: null,
    groupedDevices: [],
    ungroupedDevices: [],
};

const getters = {
    getRawDevices: state => state.rawDevices,
    getViewDevices: state => {
        if(state.searchString !== ''){
            return state.viewDevices.filter((d) => d.type !== 'group' || (d.type === 'group' && d.devices.length > 0))
        }
        
        return state.viewDevices},
    getViewNamelist: state => {
        let namelist = [];
        state.viewDevices.forEach(dev => {
            if (dev.type == 'device') {
                namelist.push({
                    id: dev.id,
                    title: dev.name + (dev.description ? ' | ' + dev.description : '')
                });
            }
            else {
                dev.children.forEach((child) => {
                    namelist.push({
                        id: child.id,
                        title: child.name + (child.description ? ' | ' + child.description : '')
                    });
                })
            }
        })
        return namelist;
    },
    getRawNameList: (state) => {
        let namelist = [];
        state.rawDevices.forEach(dev => {
            namelist.push({
                id: dev.id,
                title: dev.name + (dev.description ? ' | ' + dev.description : '')
            });
        })
        return namelist;
    },
    getHidelist: state => state.hidelist,
    getDeviceById: (state) => (id) => {
        return state.viewDevices.find(dev => dev.id === id);
    },
    getDeviceNameFromId: (state) => (id) => {
        let device = state.rawDevices.find(dev => dev.id == id);
        if (!device) {
            return `ID: ${id}`;
        }
        return device['name'] + (device['description'] ? ' | ' + device['description'] : '');
    },
    getSearchString: (state) => state.searchString,
    getGroups: (state) => state.groups,
    getGroupedDeviceIds: (state) => {
        let deviceIds = [];
        state.groups.forEach((g) => {
            deviceIds = deviceIds.concat(g.devices);
        })
        return deviceIds;
    }
};

const actions = {
    async loadDevices({ commit/*, dispatch*/ }) {
        //query devices
        axios
            .get('https://remote.eospowersolutions.com/my-devices/api/devices?status=all', {
                // withCredentials: true,
            })
            .then(response => {
                commit('setDevices', response.data);
                commit('applySearchString');
                commit('groupDevices')
                // dispatch('applyFilters');
            })
            .catch(function () {
                // dispatch('alerts/fireAlert', [error, 'danger'], { root: true });
                commit('requestFailure');
            });
    },

    changeSearchstring({ commit }, searchstring) {
        commit('setSearchString', searchstring)
        commit('applySearchString');
    },

    setSortorder({ commit, dispatch }, sortorder) {
        commit('changeSortorder', sortorder);
        dispatch('applyFilters');
    },

    applyFilters({ commit }) {
        commit('resetViewDevices');
        commit('applySearchString');
        commit('applyHidelist');
        commit('groupDevices');
        commit('sortViewDevices');
    },

    groupDevices({ commit }, [groups]){
        commit('setDeviceGroups', [groups])
        commit('groupDevices')
    }
};

const mutations = {
    setDevices: (state, devices) => {
        // add device property to identify legacy devices:
        state.rawDevices = devices.map((device) => {
            // targetHost = '172.17.0.1' if device runs docker, since all legacy devices doen's run docker, it can be used to identify them.
            return { ...device, legacy: (device.targetHost !== '172.17.0.1')}
        });
        // TODO: apply filters, etc...
        state.filteredDevices = state.rawDevices;
        state.viewDevices = state.filteredDevices;
    },

    resetViewDevices: (state) => (state.viewDevices = generateViewData(state.rawDevices)),

    requestFailure: (state) => {
        state.devices = null;
        state.viewDevices = null;
    },
    changeSortorder: (state, sortorder) => {
        state.sortorder = sortorder;
    },

    sortViewDevices: (state) => {
        let sortA = sortArray[state.sortorder];
        state.viewDevices = state.viewDevices.sort((a, b) => sortA.indexOf(a.state) - sortA.indexOf(b.state))
    },
    setSearchString: (state, searchString) => {
        state.searchString = searchString
    },
    applySearchString: (state) => {
        if (state.searchString == '') {
            state.viewDevices = state.filteredDevices;
            return;
        }
        let regex = new RegExp(state.searchString, 'i'); //RegExp with flag 'i' makes it caseinsensitive!
        let searchProperties = ['name', 'description', 'id', 'tags', 'serial']
        state.viewDevices = state.filteredDevices.filter((element) => {
            for (let propIndex in searchProperties) {
                if (element[searchProperties[propIndex]].search(regex) != -1) {
                    return true;
                }
            }
            return false;
        })
    },

    setDeviceGroups: (state, [groups]) => {
        state.groups = groups
    },

    groupDevices: (state) => {
        if(!state.viewDevices || !state.groups)
            return;

        state.groups.forEach(group => {
            // get array of all children (viewDevices)
            let children = [...state.viewDevices.filter((dev) => {
                    return group.devices.includes(dev.id);
                })];
            // remove children from viewDevices
            state.viewDevices = state.viewDevices.filter((dev) => {
                return !group.devices.includes(dev.id);
            })

            // add new viewDevice (type 'group') with children
            state.viewDevices.unshift({
                name: group.name,
                id: group.id,
                type: 'group',
                devices: children,
            })
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


////////////////////////////////////////////////////////////////////
// UTILS
////////////////////////////////////////////////////////////////////
const sortArray = {
    success: ['success', 'warning', 'danger'],
    warning: ['warning', 'danger', 'success'],
    danger: ['danger', 'warning', 'success']
}

// const states = ['success', 'warning', 'danger'];
// function getWorstState(array)
// {
//     var state = 0;
//     array.forEach((i) => {
//         if(states.indexOf(i.state) > state)
//         {
//             state = states.indexOf(i.state);
//         }
//     })
//     return states[state];
// }