<template>
  <div class="locale-switcher">
    <i class="fas fa-globe mx-2"></i>
    <b-form-select v-model="selectedLanguage" @change="changeLocale">
      <b-form-select-option :value="locale.code" v-for="locale in locales" :key="locale.code"> {{ locale.name }}</b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import { setDocumentLang } from '@/util/i18n/document';
export default {
  data () { return {locales: getSupportedLocales(), selectedLanguage: this.$i18n.locale}},
  methods: {
    changeLocale() {
      const locale = this.selectedLanguage;
      let routeArray = this.$route.path.split('/');
      // Route e.g.: /en/register
      // [0] .. empty, [1] .. lang, [>1] .. path

      routeArray[1] = locale;
      // const newRoute = routeArray.join('/');
      // this.$router.push(newRoute, ()=>{});
      localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;
      setDocumentLang(locale);
    },
  },
};
</script>

<style>
.locale-switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>