<template>
  <!-- eslint-disable vue/no-unused-components -->
  <div id="app">
    <b-navbar type="light" variant="primary" class="fixed-top">
      <b-navbar-brand>
        <router-link :to="{name: 'Home'}">
          <img src="@/assets/eos.png" alt="EOS" class="logo"
        /></router-link>
      </b-navbar-brand>

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

      <b-navbar-nav>
        <language-switcher />
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button @click="toggleSidebar" v-if="getLoggedin"><i class="fas fa-bars"></i></b-button>
      </b-navbar-nav>
    </b-navbar>

    <sidebar
      v-if="getLoggedin"
      :open="expandSidebar"
      
      @closeSidebar="toggleSidebar"
    />

    <div
      class="content-wrapper"
      :class="{ 'sidebar-expanded': expandSidebar && getLoggedin }"
    >
      <transition :name="transitionName">
        <router-view class="view" />
      </transition>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import { setDocumentLang } from "@/util/i18n/document";
import Sidebar from "./components/Sidebar.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  components: {
    Sidebar,
    LanguageSwitcher,
  },
  computed: {
    ...mapGetters("users", ["getLoggedin", "allowUserAdministration"]),
    ...mapGetters("alerts", ["getMessage", "getType", "getTitle"]),
  },
  data() {
    return {
      expandSidebar: true,
      transitionName: "slide-left",

      
    };
  },
  methods: {
    toast(
      title,
      message,
      variant,
      toasterPosition = "b-toaster-top-center",
      append = true
    ) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: toasterPosition,
        solid: true,
        appendToast: append,
        variant: variant,
      });
    },

    checkToast() {
      if (this.getMessage) {
        this.toast(this.getTitle, this.getMessage, this.getType);
      }
    },

    toggleSidebar() {
      this.expandSidebar = !this.expandSidebar;
    },
  },
  watch: {
    getMessage: "checkToast",

    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
  mounted() {

    axios.defaults.baseURL = "https://remote.eospowersolutions.com";
  },
  created() {
    //check the language from localstorage
    const locale = localStorage.getItem("locale");
    if (locale) {
      this.$i18n.locale = locale;
      setDocumentLang(locale);
    }
  },
};
</script>

<style>
@font-face {
  font-family: "textfont";
  src: url("./fonts/Sofia-Pro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "headfont";
  src: url("./fonts/Lato-HeavyItalic.ttf") format("truetype");
}
@font-face {
  font-family: "leadfont";
  src: url("./fonts/Sofia Pro Bold.otf") format("opentype");
}

body {
  font-family: "textfont";
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "headfont";
}

.lead {
  font-family: "leadfont";
}

.logo {
  height: 30px;
}
</style>

<style lang="scss">
@import "@/theme.scss";


.content-wrapper {
  margin-top: $navbar-height;
  padding-left: 0px;
}
@include media-breakpoint-up(sm) {
  .content-wrapper {
    transition: all 0.5s ease-in-out;
    &.sidebar-expanded {
      padding-left: $sidebar-width-expanded;
    }
    padding-left: $sidebar-width-collapsed;
  }
}

.view {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translateX(10%); 
  transform: translateX(10%);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translateX(-10%); 
  transform: translateX(-10%);
}
</style>
