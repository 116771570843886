import axios from 'axios'
import router from '../../router'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import i18n from '@/i18n.js'


const state = {
  user: {},
  token: '',
  permissions: [],
};

const getters = {
  username: state => state.user.name,
  initials: state => {
    if (!state.user.attributes) return '';
    let atts = state.user.attributes;
    let fnFilter = atts.filter(e => e.name == 'firstName')
    let lnFilter = atts.filter(e => e.name == 'lastName')
    let fn = fnFilter.length == 1 ? fnFilter[0].value : undefined
    let ln = lnFilter.length == 1 ? lnFilter[0].value : undefined
    if (fn && ln) {
      return fn.charAt(0) + ln.charAt(0);
    } else {
      return state.user.name.charAt(0);
    }
  },
  getLoggedin: state => state.token != '',
  getToken: state => state.token,
  getCredentials: state => state.credentials,

  getPermissions: state => state.permissions,
  allowUserAdministration: state => state.permissions.includes('authAdmin'),
  showDeviceProperties: state => state.permissions.includes('reflector.device.*') || state.permissions.includes('reflector.device.update'),
  showDeviceState: state => state.permissions.includes('eos.device.showDeviceState') || state.permissions.includes('reflector.device.*')
};

const actions = {
  /**
   * Gets called when login page is visited.
   * Checks if a valid token is found in localStorage and logs in if possible.
   */
  checkLogin({ commit, dispatch }) {
    let token = localStorage.getItem("token");

    if (!token) {
      // return if no token is present in local Storage
      return;
    }
    try {
      var decoded = jwt_decode(token);
      let exp = moment.unix(decoded['exp']);
      // console.log("Expires: ", exp.toISOString());

      if (exp > moment()) {
        // token is still valid, login and use it
        commit('loginSuccess', [token, '', '']);
        dispatch('getUserPermissions', [decoded['sub']]);
        dispatch('getUserGroups', [decoded['sub']]);
      }
      else {
        //token is expired, remove it from localStorage
        // console.log("Removing invalid token");
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.warn('invalid token');
      localStorage.removeItem("token");
    }
  },
  login({ commit, dispatch }, [username, password]) {
    //reset alert
    dispatch('alerts/resetAlert', null, { root: true });

    //Obtain Bearer Token
    const qs = require('querystring');
    const data = {
      username: username,
      password: password,
      application: 'eos-ui',
    }

    axios
      .post('https://remote.eospowersolutions.com/my-devices/api/token', qs.stringify(data))
      .then(response => {
        // console.log("Obtained Token: ");
        // console.log(response.data);
        let token = response.data['token'];
        commit('loginSuccess', [token, username, password]);
        dispatch('getUserPermissions', [username]);
        dispatch('getUserGroups', [username]);

      })
      .catch(function ( /*error*/) {
        dispatch('alerts/fireAlert', [i18n.t('Error'), i18n.t('login.invalidLogin'), 'danger'], { root: true });
        commit('loginError');
      });

  },
  async getUserPermissions({ commit }, [username]) {
    let permissions = [];
    let gotPermissions = false;
    await axios
      .get(`/my-devices/api/users/${encodeURI(username)}`)
      .then(async (res) => {
        let user = res.data
        permissions = permissions.concat(user.permissions)
        gotPermissions = true;
        commit('setUser', [user]);
      })
      .catch(() => {
        //401 => not authorized when user has no admin permissions
      })
    // if it is not possible to query the users permissions, roles do not need to be queried.
    if (!gotPermissions) return;

    let roles = [];
    await axios
      .get(`/my-devices/api/users/${encodeURI(username)}/roles`)
      .then((res) => {
        //res.data holds a list of roleNames. 
        roles = res.data;
      })
      .catch((err) => { console.error(err) })
    //Now query the permissions for each role and append it to permissions.
    for (let i in roles) {
      let role = roles[i]
      await axios
        .get(`/my-devices/api/roles/${role}/permissions`)
        .then(res => {
          permissions = permissions.concat(res.data);
        })
        .catch(() => { })

    }
    commit('writeUserPermissions', [permissions]);


  },
  async getUserGroups({ commit, dispatch }, [username]) {

    await axios
      .get(`/my-devices/api/users/${encodeURI(username)}/attributes/deviceGroupAttributes`)
      .then(async (res) => {
        let groups = []

        let attributeNames = res.data
        if (!attributeNames || attributeNames === "") attributeNames = '[]'
        attributeNames = JSON.parse(attributeNames)

        for (let i = 0; i < attributeNames.length; i++) {
          try {
            let name = attributeNames[i];

            let group = (await axios.get(`/my-devices/api/users/${encodeURI(username)}/attributes/${name}`)).data
            if (group !== "") {
              groups.push(JSON.parse(group))
            }
          } catch (error) {
            console.error(error)
          }
        }

        commit('setGroups', [groups])
        dispatch('devices/groupDevices', [groups], { root: true })
      })
  },
  /**
   * Gets called from extern when usergroups are updated (Profile.vue)
   */
  setUserGroups({commit, dispatch}, [groups]){
    commit('setGroups', [groups])
    dispatch('devices/groupDevices', [groups], { root: true })
  },
  async logout({ commit }) {
    const qs = require('querystring');
    const data = {
      action: 'logout',
    }
    try {
      // remove any existing session
      await axios
        .post('/', qs.stringify(data))
    } catch (error) {
      // will fail on localhost due to missing access-control header
    }


    //remove token from localStorage
    commit('logoutSuccess');
  },
}

const mutations = {
  loginSuccess: (state, [token]) => {
    let decoded = jwt_decode(token);
    state.user = decoded['sub']; //username is in 'subject' field of token
    state.token = token;

    // set axios header (=> gets send with all requests)
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };

    localStorage.setItem("token", state.token);

    router.push({ name: 'Devices', });
  },
  loginError: (state) => {
    state.user = {};
    state.token = '';
    axios.defaults.headers = {
      'Authorization': 'None',
    };
    //router.push('/login');
  },

  logoutSuccess: (state) => {
    state.user = {};
    state.token = '';

    axios.defaults.headers = {
      'Authorization': 'None',
    };

    // remove item from localstorage
    localStorage.removeItem('token');
    // navigate to home page
    router.push({ name: 'Home', });
  },

  writeUserPermissions: (state, [permissions]) => {
    state.permissions = permissions;
  },
  setUser: (state, [user]) => {
    state.user = user;
  },
  setGroups: (state, [groups]) => {
    state.groups = groups;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}