import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users'
import alerts from './modules/alerts'
import devices from './modules/devices'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
      users,
      alerts,
      devices,
  }
})