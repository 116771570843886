<template>
  <div>
    <div class="sidebar-wrapper d-none d-sm-flex flex-column" :class="{ expand: open }">
      <div class="sidebar ">
        <div class="profile-info" v-if="getLoggedin">
          <b-avatar variant="primary" :text="initials" class="mr-2"></b-avatar>

          <p v-if="open">
            <a href="#" @click="logout">{{ $t("login.logout") }}</a>
          </p>
        </div>
        <hr />

        <ul>
          <li
            class="nav-item"
            v-for="(item, index) in visibleMenuItems"
            :key="index"
          >
            <router-link
              :to="item.to"
              class="nav-link"
              v-b-tooltip.hover.right
              :title="!open ? $t(item.text) : ''"
            >
              <i :class="item.iconClass"></i>
              <transition name="slide" appear>
                <span class="nav-text" v-if="open">{{ $t(item.text) }}</span>
              </transition>
            </router-link>
          </li>
        </ul>
      </div>
        <p class="version">v{{version}}</p>
    </div>

    <!-- Mobile Menu -->
    <transition name="slide-fade">
      <!-- open is inverse on mobile menu that it does not starts in open state -->
      <nav class="navigation d-block d-sm-none" v-if="!open" v-show="!open">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-felx ml-3" v-if="getLoggedin">
            <b-avatar
              variant="primary"
              :text="initials"
              class="mr-2"
            ></b-avatar>
            <a href="#" @click="logout">{{ $t("login.logout") }}</a>
          </div>
          <a id="x" @click="$emit('closeSidebar')" href="#"
            ><i class="fas fa-times"></i
          ></a>
        </div>
        <div class="nav-content">
          <router-link
            v-for="(item, index) in visibleMenuItems"
            :key="index"
            :to="item.to"
            @click.native="$emit('closeSidebar')"
          >
            <i :class="item.iconClass" class="mr-3"> </i
            ><span>{{ $t(item.text) }}</span>
          </router-link>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["open"],
  components: {},
  computed: {
    ...mapGetters("users", [
      "username",
      "getLoggedin",
      "allowUserAdministration",
      "initials",
    ]),
    menuItems() {
      return [
        {
          to: { name: "Devices" },
          iconClass: "fas fa-solar-panel",
          text: "menu.my-devices",
        },
        {
          to: { name: "Users" },
          iconClass: "fas fa-users",
          text: "menu.users",
          show: this.allowUserAdministration,
        },
        {
          to: { name: "Roles" },
          iconClass: "fas fa-user-tag",
          text: "menu.roles",
          show: this.allowUserAdministration,
        },
        {
          to: { name: "Profile" },
          iconClass: "fas fa-user",
          text: "menu.profile",
        },
      ];
    },
    visibleMenuItems() {
      return this.menuItems.filter((mi) => !("show" in mi) || mi.show);
    },
  },
  methods: {
    ...mapActions("users", ["logout"]),
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
};
</script>

<style lang="scss">
@import "@/theme.scss";
* {
  transition: all 0.2s ease-out;
}
.sidebar-wrapper {
  transition: all 0.5s ease-in-out;
  &.expand {
    width: $sidebar-width-expanded;

    .sidebar {
      .profile-info {
        margin-left: 1rem;
      }
    }
  }
  position: fixed;
  top: 57px;
  background: rgba($color: $secondary, $alpha: 1);
  height: calc(100vh - #{$navbar-height});
  width: $sidebar-width-collapsed;
  padding-top: 1rem;
  justify-content: space-between;

  .sidebar {
    color: $light;
    ul {
      list-style-type: none;
      padding-left: 0.5rem;
      li {
        margin: 0.5rem 0;

        a {
          color: $light;
          &.router-link-exact-active {
            background: lighten($color: $secondary, $amount: 10);
          }
          i {
            margin-right: 1rem;
          }
        }
      }
    }
    .profile-info {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-left: 0.5rem;
    }
  }
}

.navigation {
  z-index: 10000;
  position: fixed;

  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-x: hidden; /* disable horizontal scrolling */
  transition: 0.5s;
  background-color: rgba($dark, 0.85);
  display: flex;
  flex-direction: column;

  #x {
    align-self: flex-end;
    margin: 1rem;
    margin-right: 2rem;
    font-size: 3rem;
    color: $light;
    height: 10vh;
  }

  .nav-content {
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    align-items: center;
    // a {
    //   color: $dark;
    // }

    a {
      background-color: $dark;
      color: $light;
      padding: 1rem;
      margin: 1rem 0;
      align-self: stretch;
      text-align: left;
      font-size: 1rem;
      border-left: 8px solid $primary;
      border-bottom: 2px solid $primary;
    }
  }
}

.version {
  align-self: center;
  color: lighten($secondary, 30);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  width: 0;
  // opacity: 0;
}

.nav-link {
  overflow: hidden;
  white-space: nowrap;
}
.nav-text {
  white-space: nowrap;
  overflow: hidden;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.1s ease-in;
  // transition-delay: 1s;
}
.slide-enter,
.slide-leave-to {
  // transition: all 1s ease-in;
  transform: scaleX(0);
}
</style>